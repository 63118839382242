


























































import { NetworkStatisticsPanelTs } from './NetworkStatisticsPanelTs';
export default class NetworkStatisticsPanel extends NetworkStatisticsPanelTs {}
