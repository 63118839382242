

















// @ts-ignore
import { DashboardTs } from './DashboardTs';
export default class Dashboard extends DashboardTs {}
