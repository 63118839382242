// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.dashboard-container[data-v-fc0f6108]{position:relative;width:100%;height:100%;display:grid;grid-template-columns:4.5rem auto;grid-template-rows:100%;grid-column-gap:.3rem;font-size:.14rem}.dashboard-container .dashboard-right-outer-container .dashboard-right-container[data-v-fc0f6108]{height:100%;position:relative;display:grid;grid-template-rows:1.3rem auto;grid-template-columns:100%;grid-row-gap:.35rem;width:100%}.dashboard-container .dashboard-right-outer-container .dashboard-right-container .right-bottom-container[data-v-fc0f6108]{position:relative;display:grid;grid-template-rows:.7rem calc(100% - .7rem);grid-template-columns:100%;overflow-y:hidden;background-color:#fff;border-radius:.08rem}", ""]);
// Exports
module.exports = exports;
