





































import { ProfileBalancesPanelTs } from './ProfileBalancesPanelTs';
export default class ProfileBalancesPanel extends ProfileBalancesPanelTs {}
