






































































import { MosaicBalanceListTs } from './MosaicBalanceListTs';
export default class MosaicBalanceList extends MosaicBalanceListTs {}
